<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-date-picker type="month" value-format="yyyy-MM" placeholder="选择考核月份" v-model="params.month" :picker-options="pickerOptions" :editable="false" :clearable="false" @change="getCheckin({ ...params, page: 1})"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'unique_id', label: 'name', checkStrictly: true}" @change="getCheckin({ ...params, page: 1})" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employee_id" placeholder="请选择员工" @change="getCheckin({ ...params, page: 1})" filterable multiple collapse-tags>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name+(employee.separated_at ? '(已离职)' : '')" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="checkings_dialog = true;" v-if="$utils.create($api.URI_EMPLOYEES_WORKFORCES)">添加排班</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table ref="checking_in_table" class="checking_in scroll-wrapper absolute" height="100%" :data="checkings" :size="theme.size" :row-class-name="tableRowClassName" border>
					<el-table-column type="selection" width="42"></el-table-column>
					<el-table-column label="员工" prop="employee.name" min-width="80"></el-table-column>
					<el-table-column label="考核月份" prop="period_at" min-width="70" v-if="!params.month"></el-table-column>
					<el-table-column v-for="day in month_days" :key="day.date || day.day" :prop="`day_${day.day}`" min-width="50">
						<template slot="header">
							<p class="tac">{{day.day}}</p>
							<p class="tac" v-if="day.dayOfWeek">{{day.dayOfWeek}}</p>
						</template>
						<div style="line-height: 1.2; text-align: center;" slot-scope="scope">
							<p>正常</p>
							<p>{{scope.row.id}}</p>
						</div>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div class="p20" slot="footer"></div>
		<template slot="other"></template>
	</main-table-a>
</template>

<style>
	.el-table.checking_in .warning-row {
		background: oldlace;
	}

	.el-table.checking_in .success-row {
		background: #f0f9eb;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA,
		},
		computed: {
			...mapState(['theme', 'oa_departments']),
			tree_departments () {
				return this.$utils.buildTree(Object.values(this.oa_departments));
			},
			month_days () {
				var monthDays = [];
				const { month } = this.params;
				if (!month) return Array(31).fill(0).map((_, index) => { return {day: index + 1}; });
				const lastDayOfMonth = this.$moment(month).endOf('month');
				for (let i = 0; i < lastDayOfMonth.format('DD'); i++) {
					const day = lastDayOfMonth.clone().subtract(i, 'days');
					monthDays.push({
						day: day.format('D'),
						date: day.format('YYYY-MM-DD'),
						dayOfWeek: day.locale('zh-cn').format('dd')
					});
				}
				monthDays.reverse();
				return monthDays;
			}
		},
		methods: {
			tableRowClassName({row}) {
				if (row.signatured) return 'success-row';
				return '';
			},
			async submitCheckin (data) {
				this.loading = true;
				const res = await this.$http.post(this.$api.URI_EMPLOYEES_WORKFORCES, data);
				const { code, msg: message } = res.data;
				this.loading = false;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.getCheckin(this.params);
						this.checkings_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'checking_form':
							if (this.loading) return false;
							// console.log(this.checking);
							this.submitCheckin(this.checking);
						break;
					}
				});
			},
			async getCheckin (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_EMPLOYEES_WORKFORCES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.checkings = result.data;
				this.employees = result.employees;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				checking: {
					vacations: []
				},
				checkings: [],
				employees: [],
				loading: false,
				checkings_dialog: false,
				params: {
					month: this.$moment().format('Y-MM'),
					perPage: 10,
				},
				pickerOptions: {
					disabledDate (time) {
						// 获取当前时间
						const now = new Date();
						// 获取当前年份和月份
						const currentYear = now.getFullYear();
						// 月份是从0开始的，所以要加1
						const currentMonth = now.getMonth() + 1;
						// 获取选择日期的年份和月份
						const selectYear = time.getFullYear();
						// 月份是从0开始的，所以要加1
						const selectMonth = time.getMonth() + 1;
						// 如果选择的年份大于当前年份，或者年份相同但月份大于当前月份，则禁用该日期
						return selectYear > currentYear || (selectYear === currentYear && selectMonth > currentMonth);
					}
				}
			}
		},
		created () {
			this.getCheckin(this.params, true);
		}
	};
</script>